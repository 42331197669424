import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import HeroOther from "../components/hero-other";
import SocietyContactForm from "../components/societyContactForm";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";

const SocietyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "society-enquiries" }) {
        seoFieldGroups {
          localBusinessSchema
          metaDescription
          metaTitle
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        societyEnquiriesPageFieldGroups {
          societyEnquiriesBannerSection {
            heading
            description
            subHeading
            cta1 {
              target
              title
              url
            }
            cta2 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          societyEnquiriesGetInTouch {
            formBtnLabel
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          workSection {
            description
            heading
            subHeading
            cta1 {
              target
              title
              url
            }
          }
        }
      }
      # allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
      #   nodes {
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           formats: [AUTO, WEBP]
      #           quality: 100
      #           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      #           layout: CONSTRAINED
      #           placeholder: BLURRED
      #         )
      #       }
      #     }
      #     caption

      #     permalink
      #     timestamp
      #   }
      # }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, societyEnquiriesPageFieldGroups },
    site,
  } = data;

  const {
    societyEnquiriesBannerSection,
    societyEnquiriesGetInTouch,
    workSection,
  } = societyEnquiriesPageFieldGroups;

  const siteUrl = site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Society Enquiries",
        item: {
          url: `${siteUrl}/society-enquiries`,
          id: `${siteUrl}/society-enquiries`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/society-enquiries`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />
      <div>
        <Layout>
          {societyEnquiriesBannerSection &&
            !checkPropertiesForNull(societyEnquiriesBannerSection, [
              "heading",
            ]) && (
              <HeroOther
                smallHeading={societyEnquiriesBannerSection?.subHeading}
                title={societyEnquiriesBannerSection?.heading}
                externalLink={
                  societyEnquiriesBannerSection?.cta1.target === "_blank"
                }
                button1Text={societyEnquiriesBannerSection?.cta1?.title}
                button1Link={societyEnquiriesBannerSection?.cta1?.url}
                button2Text={societyEnquiriesBannerSection?.cta2?.title}
                button2Link={societyEnquiriesBannerSection?.cta2?.url}
                description={
                  <>
                    {societyEnquiriesBannerSection?.description && (
                      <SafeHtmlParser
                        htmlContent={societyEnquiriesBannerSection?.description}
                      />
                    )}
                  </>
                }
                backgroundImage={
                  societyEnquiriesBannerSection.backgroundImage.localFile
                    .childImageSharp.gatsbyImageData
                }
              />
            )}
          {workSection && !checkPropertiesForNull(workSection, ["heading"]) && (
            <section className="py-5 py-lg-7 bg-primary">
              <Container>
                <Row className="justify-content-center">
                  <Col className="text-center" lg={8}>
                    {workSection?.subHeading && (
                      <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                        {workSection?.subHeading}
                      </p>
                    )}

                    <h2 className="line-heading text-white mb-4 fs-1">
                      {workSection?.heading}
                    </h2>
                    {workSection?.description && (
                      <div className="text-white text-center">
                        <SafeHtmlParser
                          htmlContent={workSection?.description}
                        />
                      </div>
                    )}
                    {workSection.cta1 && workSection.cta1.url && (
                      <Button
                        style={{ borderRadius: "0px" }}
                        variant="outline-white"
                        target="_blank"
                        rel="noreferrer"
                        href={workSection.cta1.url}
                        className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
                      >
                        {workSection.cta1.title}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
          {societyEnquiriesGetInTouch &&
            !checkPropertiesForNull(societyEnquiriesGetInTouch, ["image"]) && (
              <section>
                <Container>
                  <Row className="g-5 justify-content-between align-items-center h-100 gx-lg-7  gx-xl-0 py-4">
                    <Col lg={6} xl={5}>
                      <div id="society-contact-form">
                        <SocietyContactForm buttonText={societyEnquiriesGetInTouch?.formBtnLabel}/>
                      </div>
                    </Col>
                    {societyEnquiriesGetInTouch.image && (
                      <Col styl={{ minHeight: "100%" }} lg={6} xl={5}>
                        <div
                          className="h-100"
                          style={{ borderRadius: "8px", overflow: "hidden" }}
                        >
                          <GatsbyImage
                            style={{ borderRadius: "8px", height: "700px" }}
                            className="w-100 "
                            image={
                              societyEnquiriesGetInTouch.image.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            alt={societyEnquiriesGetInTouch.image.altText}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default SocietyPage;
