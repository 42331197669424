import { useForm, ValidationError } from "@formspree/react";
import React, { useState, useEffect } from "react";
import {
	ButtonToolbar,
	Col,
	InputGroup,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function SocietyContactForm({ buttonText }) {
	const [selectedButton, setSelectedButton] = useState("AM");
	const [gclid, setGclid] = useState(""); // New state to store gclid

	const handleButtonClick = (value) => {
		setSelectedButton(value);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}
	}, []);

	const [state, handleSubmit] = useForm("myyqrele");

	const handleFormSubmit = (event) => {
		event.preventDefault(); // Prevent default form submission

		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerPhone = formData.get("phone");
		const customerName = formData.get("name");
		const numberOfPlayers = formData.get("Number of players");
		const socName = formData.get("socName");

		// Push to dataLayer after form submit
		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "SocietyContactForm", // You can change this based on the form name
				customer_email: customerEmail,
				customer_phone_number: customerPhone,
			});
		}

		handleSubmit(event); // Proceed with Formspree submission
	};

	if (state.succeeded) {
		window.location.href = "/thank-you";
		return null;
	}

	return (
		<Form className="w-100 py-5 text-center" onSubmit={handleFormSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="name"
					placeholder="Name"
					type="text"
					required
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mb-3" controlId="numberOfPlayers">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="Number of players"
					placeholder="Number of players"
					type="number"
					required
				/>
			</Form.Group>
			<ValidationError
				prefix="Number of players"
				field="numberOfPlayers"
				errors={state.errors}
			/>

			<Form.Group className="mb-3" controlId="socName">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="socName"
					placeholder="Society Name"
					type="text"
					required
				/>
			</Form.Group>
			<ValidationError
				prefix="Society Name"
				field="socName"
				errors={state.errors}
			/>

			<Form.Group className="mb-3" controlId="phone">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="phone"
					placeholder="Phone"
					type="tel"
					required
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="phone" errors={state.errors} />

			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="email"
					type="email"
					placeholder="Email"
					required
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-3" controlId="date">
				<Form.Control
					className="py-3 pb-xl-5 fs-5"
					name="date"
					type="text"
					placeholder="Date"
				/>
			</Form.Group>
			<ValidationError prefix="Date" field="date" errors={state.errors} />

			<div className="d-flex align-items-center justify-content-between">
				<label htmlFor="time" className="ps-3 fs-5 text-start">
					Preferred tee time
				</label>
				<ToggleButtonGroup
					id="time"
					type="radio"
					name="time"
					value={selectedButton}
				>
					<ToggleButton
						variant="outline-primary"
						value="AM"
						onClick={() => handleButtonClick("AM")}
						className="py-3 px-5"
					>
						AM
					</ToggleButton>
					<ToggleButton
						variant="outline-primary"
						value="PM"
						onClick={() => handleButtonClick("PM")}
						className="py-3 px-5"
					>
						PM
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<ValidationError prefix="Time" field="time" errors={state.errors} />

			{/* Hidden field for gclid */}
			<Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>

			<div className="text-center">
				<Button
					variant="primary"
					className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					{buttonText ?? "Submit"}
				</Button>
			</div>
		</Form>
	);
}

export default SocietyContactForm;
